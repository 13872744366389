<div (click)='goToFieldsDetails(field.id)' class='field-card'>
  <div class="details">
    <div class="title" [matTooltip]="field.name">{{ field.name }}</div>
    <div class="vins-wrapper">
      <span class="found-vins-label">{{ 'FieldsManagement.Fields.FoundVins' | translate }}:</span>
      <div *ngIf='field.vehicles.length > 0' class='vins'>
        <div *ngFor='let vin of field.vehicles' class='vin-circle'>
          <img [matTooltip]="vin.vin" [src]='vin.vehiclePhotoUrl' alt='{{ vin.vin }}'>
        </div>
      </div>
      <div *ngIf='field.vehicles.length <= 0'>-</div>
    </div>
  </div>
  <div class='map'>
    <img [src]='field.mapPreviewUrl' alt='{{ field.name }}'>
  </div>
</div>
