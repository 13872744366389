<div class="page-container" [ngClass]="alwaysMobile ? 'always-mobile' : ''">
  <div *ngIf='loading' class='spinner-container'>
    <mat-spinner></mat-spinner>
  </div>
  <button (click)='openCreateDialogDialog($event)'
  *ngIf="showActions"
  color='dark-primary'
  class="desktop-button actions"
  mat-button>{{'EquipManagement.Actions.Create' | translate }}</button>
  <button (click)='goToCreateNewEquip()'
  *ngIf="showActions"
  color='dark-primary'
  class="mobile-button actions"
  mat-button>{{'EquipManagement.Actions.Create' | translate }}</button>
  <div class="section detected-equip" *ngIf="equipmentList?.length > 0">
    <h2 *ngIf="showTitles">{{'EquipManagement.Fields.ImplementsList' | translate }} <div *ngIf="equipmentList?.length >= 0" class="count-circle">{{ equipmentList?.length }}</div></h2>
    <div class="cards">
      <app-equip-card *ngFor="let equip of equipmentList" [equip]="equip" [linked]="true"></app-equip-card>
    </div>
  </div>
  <div class="section detected-equip" *ngIf="linkedEquipmentList?.length > 0">
    <div style="display: grid;
        margin-bottom: 1em;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: .8em;">
        <div style="display: flex;">
          {{'EquipManagement.Fields.AllDevices' | translate }}
          <div *ngIf="linkedEquipmentList?.length >= 0" class="count-circle">{{ linkedEquipmentList?.length }}</div>
        </div>
      <div style="height: 1px; background: rgba(34, 41, 48, 0.15);"></div>
    </div>
    <div class="cards">
      <app-equip-card *ngFor="let equip of linkedEquipmentList" [equip]="equip" [linked]="false"></app-equip-card>
    </div>
  </div>
</div>