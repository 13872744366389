import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserRoleDirective } from '@directives/user-role.directive';
import { LoaderComponent } from '@components/loader/loader.component';
import { MessageComponent } from '@components/message/message.component';
import { SidebarComponent } from '@components/sidebar/sidebar.component';
import { NotificationPopupComponent } from '@components/notification-popup/notification-popup.component';

import { UserDialogComponent } from '@components/header/user-dialog/user-dialog.component';
import { HeaderComponent } from '@components/header/header.component';
import { ErrorInterceptor } from '@helpers/error.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MaintenanceAddModalComponent } from '@shared/components/maintenance-add-modal/maintenance-add-modal.component';
import { MaintenanceSummaryComponent } from '@shared/components/maintenance-summary/maintenance-summary.component';
import { MatDividerModule } from '@angular/material/divider';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatStepperModule } from '@angular/material/stepper';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MaintenanceTableComponent } from '@shared/components/maintenance-table/maintenance-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PaginatorComponent } from '@components/paginator.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TractorDetailAlarmsComponent } from '@components/tractor-detail-alarms/tractor-detail-alarms.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaintenanceStepsModalComponent } from '@components/maintenance-steps-modal/maintenance-steps-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RedirectionComponent } from '@components/redirection.component';
import { MatSortModule } from '@angular/material/sort';
import { MaintenanceDialogComponent } from '@shared/components/maintenance-dialog/maintenance-dialog.component';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';

import { NotificationCenterModule } from '@novu/notification-center-angular';
import { SbdScoreComponent } from '@components/sbd-score/sbd-score.component';
import { TelInputComponent } from '@components/tel-input/tel-input.component';
import { UiScrollModule } from 'ngx-ui-scroll';

@NgModule({
  declarations: [
    SbdScoreComponent,
    UserRoleDirective,
    LoaderComponent,
    MaintenanceTableComponent,
    MessageComponent,
    MaintenanceAddModalComponent,
    MaintenanceSummaryComponent,
    SidebarComponent,
    NotificationPopupComponent,
    HeaderComponent,
    UserDialogComponent,
    RedirectionComponent,
    PaginatorComponent,
    MaintenanceStepsModalComponent,
    TractorDetailAlarmsComponent,
    MaintenanceDialogComponent,
    ConfirmDialogComponent,
    ProgressBarComponent,
    TelInputComponent,
  ],

  imports: [
    MatInputModule,
    NotificationCenterModule,
    CommonModule,
    TranslateModule,
    MatStepperModule,
    MatTableModule,
    RouterModule,
    AngularSvgIconModule,
    MatDividerModule,
    ReactiveFormsModule,
    MatIconModule,
    MatRadioModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatDialogModule,
    RouterModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatMenuModule,
    FontAwesomeModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatTooltipModule,
    UiScrollModule,
  ],
  providers: [


    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  exports: [
    UserRoleDirective,
    LoaderComponent,
    SidebarComponent, SbdScoreComponent,
    HeaderComponent,
    MaintenanceSummaryComponent,
    PaginatorComponent, TelInputComponent,
    ProgressBarComponent,
    RedirectionComponent,
    TractorDetailAlarmsComponent,
    MaintenanceStepsModalComponent,
  ],
})
export class SharedModule {
}
