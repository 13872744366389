<div class="equip-card" (click)="goToEquipDetails(equip.macAddress)">
  <div class="icon">
    <div *ngIf="equip?.type" class="icon-circle" [ngClass]="linked ? 'linked' : ''">
      <i [ngClass]="getIconClass(equip?.type)"></i>
    </div>
    <div *ngIf="!equip?.type" class="icon-circle" [ngClass]="linked ? 'linked' : ''">
      <i class="fa-regular fa-question-circle"></i>
    </div>
  </div>
  <div class="details">
    <div class="title">{{ equip?.name }}</div>
    <div class="vin">{{ equip?.macAddress }}</div>
    <!-- <div class="status">
      <span *ngIf="linked">{{'EquipManagement.Fields.Linked' | translate }}</span>
      <span *ngIf="!linked">{{'EquipManagement.Fields.Unlinked' | translate }}</span>
    </div> -->
  </div>
  <div class="menu-icon" (click)="openEditDialog($event)">
    <i class="material-icons" *ngIf="editable">more_vert</i>
  </div>
</div>