import { createReducer, on } from '@ngrx/store';
import * as Actions from './actions';
import * as RootActions from '../../actions';
import { PushNotificationDto, UserNotificationPreferencesDto } from '@services/notification-api.service';


export interface State {
  page: number;
  pageSize: number
  isLoading: boolean,
  notifications: PushNotificationDto[];
  totalNumber: number;
  settings: UserNotificationPreferencesDto;
  filters: any;
  unreadCount: number;
}


export const initialState: State = {
  isLoading: false,
  page: 1,
  pageSize: 10,
  notifications: [],
  totalNumber: 0,
  filters: {},
  unreadCount: 0,
  settings: {
    mail:
      {
        disabled: false,
        alert: {
          disabled: false,
          severity: 'high',
          timeAggregation: 'daily',
        },
        maintenance: {
          disabled: false,
          expiring: true,
          expired: true,
          timeAggregation: 'daily',
        },
        mailingList: [],
      },
    push:
      {
        disabled: true,
        alert: {
          disabled: true,
          severity: 'low',
        },
        maintenance: {
          disabled: true,
          expiring: false,
          expired: false,
        },
      },
    sms:
      {
        disabled: true,
        alert: {
          disabled: true,
          severity: 'low',
        },
        maintenance: {
          disabled: true,
          expiring: false,
          expired: false,
        },
      },
    whatsApp:
      {
        disabled: true,
        alert: {
          disabled: true,
          severity: 'low',
        },
        maintenance: {
          disabled: true,
          expiring: false,
          expired: false,
        },
      },

  },
};

export const selectIsNotificationsLoading = state => state.notifications.isLoading;
export const selectNotifications = state => state.notifications.notifications;  // Fixed selector
export const selectNotificationsPage = state => state.notifications.page;
export const selectNotificationsPageSize = state => state.notifications.pageSize;
export const selectNotificationsTotalNumber = state => state.notifications.totalNumber;
export const selectNotificationsFilters = state => state.notifications.filters;
export const selectNotificationsSettings = state => state.notifications.settings;
export const selectNotificationsUnreadCount = state => state.notifications.unreadCount;
export const notificationsReducer = createReducer(
  initialState,
  on(RootActions.resetState, () => ({ ...initialState })),
  on(Actions.setNotificationsLoading, (state, { value }) => ({ ...state, isLoading: value })),
  on(Actions.setNotificationsSettings, (state, { settings }) => ({
    ...state,
    settings: {
      ...state.settings,
      ...settings,
    },
  })),
  on(Actions.setNotificationsList, (state, {
    notifications,
    page,
    pageSize,
    totalNumber,
  }) => ({
    ...state,
    notifications,
    page,
    pageSize,
    totalNumber,
  })),
  on(Actions.markNotificationAsRead, (state, { id }) => {
    const updatedNotifications = state.notifications.map(notification =>
      notification.id === id ? { ...notification, read: true } : notification
    );
    return { ...state, notifications: updatedNotifications };
  }),
  on(Actions.setNotificationsCounter, (state, { count }) => ({ ...state, unreadCount: count })),
  on(Actions.markAllNotificationsAsRead, (state) => {
    const updatedNotifications = state.notifications.map(notification =>
      ({ ...notification, read: true })
    );
    return { ...state, notifications: updatedNotifications };
  })
);
