<div class="page-container" [ngClass]="{'always-mobile': alwaysMobile, 'very-small': verySmall, 'no-actions': !showActions}">
  <div *ngIf='loading' class='spinner-container'>
    <mat-spinner></mat-spinner>
  </div>
  <!-- <button (click)='goToCreateNewField()'
  *ngIf="showActions"
  color='dark-primary'
  class="actions"
  mat-button>{{'FieldsManagement.Actions.Create' | translate }}</button> -->
  <div class="section detected-fields" *ngIf="detectedFields?.length > 0">
    <h2 *ngIf="showTitles">{{ 'FieldsManagement.Fields.DetectedFields' | translate }}
      <div *ngIf='detectedFields?.length >= 0' class='count-circle'>{{ detectedFields?.length }}</div>
    </h2>
    <div class="cards">
      <app-field-card *ngFor="let field of detectedFields" [field]="field"></app-field-card>
    </div>
  </div>

  <div class="section saved-fields" *ngIf="savedFields?.length > 0">
    <h2 *ngIf="showTitles">{{ 'FieldsManagement.Fields.SavedFields' | translate }}
      <div *ngIf='savedFields?.length >= 0' class='count-circle'>{{ savedFields?.length }}</div>
    </h2>
    <div class="cards">
      <app-field-card *ngFor="let field of savedFields" [field]="field"></app-field-card>
    </div>
  </div>

  <div class="actions" *ngIf="showActions">
    <button class="btn btn-default-outline sdf-button" (click)="showDeletedFields()"
        type="button">{{'FieldsManagement.Actions.ShowDeleted' | translate}}
        <mat-icon class="icon" *ngIf="!showDeleted">expand_more</mat-icon>
        <mat-icon class="icon" *ngIf="showDeleted">expand_less</mat-icon>
    </button>
  </div>
  <div class="section deleted-fields" *ngIf="showDeleted">
    <div *ngIf="deletedFields.length > 0">
      <div class="cards">
        <app-field-card *ngFor="let field of deletedFields" [field]="field"></app-field-card>
      </div>
    </div>
    <div *ngIf="deletedFields.length <= 0">
      {{'FieldsManagement.NoDeletedFieldsFound' | translate}}.
    </div>
  </div>
</div>
