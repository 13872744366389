import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { environment, environment as env } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { DateFnsModule } from 'ngx-date-fns';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LegalPagesComponent } from './legal-pages/legal-pages.component';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { OverviewModule } from '@portal/overview/overview.module';
import { OverviewCustomerModule } from '@portal/overview-customer/overview.module';
import { MatDialogModule } from '@angular/material/dialog';
import { ActionReducer, StoreModule } from '@ngrx/store';
import { rootReducer, State } from './redux';
import { State as TractorState, tractorReducer } from './redux/modules/tractorDetail';
import { maintenanceReducer, State as MaintenanceState } from './redux/modules/maintenance';
import { alarmsReducer, State as VehiclesActiveAlarmsState } from './redux/modules/alarms';
import { State as VehiclesAlarmsState } from './redux/modules/vehicleAlarms';
import { geoviewReducer, State as GeoviewState } from './redux/modules/geoview';
import { geoviewCustomerReducer, State as GeoviewCustomerState } from './redux/modules/geoview-customer';
import { sbdReducer, State as SbdState } from './redux/modules/sbd';
import { alarmReducer, State as AlarmState } from './redux/modules/alarm';
import { notificationsReducer, State as NotificationsState } from './redux/modules/notifications';
import { EffectsModule } from '@ngrx/effects';
import { CommonEffects } from '@redux/effects';
import { storeLogger } from 'ngrx-store-logger';
import { TractorDetailEffects } from '@redux/modules/tractorDetail/effects';
import { MaintenanceEffects } from '@redux/modules/maintenance/effects';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GeoviewEffects } from '@redux/modules/geoview/effects';
import { GeoviewCustomerEffects } from '@redux/modules/geoview-customer/effects';
import { SbdEffects } from '@redux/modules/sbd/effects';
import { AlarmEffects } from '@redux/modules/alarm/effects';
import { NotificationsEffects } from '@redux/modules/notifications/effects';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/it';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/pt';
import '@angular/common/locales/global/da';
import '@angular/common/locales/global/pl';
import '@angular/common/locales/global/ro';
import '@angular/common/locales/global/ru';
import '@angular/common/locales/global/nl';
import { API_BASE_URL } from '@services/api.service';
import { API_NOTIFICATION_BASE_URL } from '@services/notification-api.service';
import { GroupsGuard, RoleGuard } from './role.guard';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAnalytics, provideAnalytics, ScreenTrackingService } from '@angular/fire/analytics';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { PerformanceMonitoringService } from '@angular/fire/compat/performance';
import { State as UserTractorsState, userTractorsReducer } from '@redux/modules/userTractors';
import { UserTractorsEffects } from '@redux/modules/userTractors/effects';
import { vehicleAlarmsReducer } from '@redux/modules/vehicleAlarms';
import { VehicleAlarmsEffectsEffects } from '@redux/modules/vehicleAlarms/effects';
import { ActiveAlarmEffects } from '@redux/modules/alarms/effects';
import { API_AGRIROUTER_BASE_URL } from '@services/sdf-agrirouter-api.service';
import { State as UserDashboardState, userDashboardReducer } from '@redux/modules/userDashboard';
import { UserDashboardEffects } from '@redux/modules/userDashboard/effects';
import { AssistancesEffects } from '@redux/modules/remote-assistances/effects';
import { assistancesReducer, State as AssistancesState } from '@redux/modules/remote-assistances';
import { AssistancesCustomerEffects } from '@redux/modules/remote-assistances-customer/effects';
import { assistancesCustomerReducer, State as AssistancesCustomerState } from '@redux/modules/remote-assistances-customer';

registerLocaleData(localeFr);

export function logger(reducer: ActionReducer<{
  root: State, tractorDetail: TractorState,
  maintenance: MaintenanceState,
  geoview: GeoviewState,
  geoviewCustomer: GeoviewCustomerState,
  userDashboard: UserDashboardState,
  sbd: SbdState,
  alarm: AlarmState,
  notifications: NotificationsState,
  userTractors: UserTractorsState,
  vehiclesAlarms: VehiclesAlarmsState,
  vehiclesActiveAlarms: VehiclesActiveAlarmsState,
  remoteAssistances: AssistancesState,
  remoteAssistancesCustomer: AssistancesCustomerState,
}>): any {
  // default, no options
  return storeLogger()(reducer);
}

export const metaReducers = environment.production ? [] : [logger];

@NgModule({
  declarations: [
    AppComponent,
    LegalPagesComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    OverviewModule,
    OverviewCustomerModule,
    MatDialogModule,
    DateFnsModule.forRoot(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule.forRoot({
      ...env.auth as any,
      httpInterceptor: {
        ...env.httpInterceptor as any,
      },
      errorPath: '/error/declined',
    }),
    MatProgressSpinnerModule,
    StoreModule.forRoot({
      root: rootReducer,
      tractorDetail: tractorReducer,
      alarm: alarmReducer,
      geoview: geoviewReducer,
      geoviewCustomer: geoviewCustomerReducer,
      userDashboard: userDashboardReducer,
      sbd: sbdReducer,
      userTractors: userTractorsReducer,
      notifications: notificationsReducer,
      maintenance: maintenanceReducer,
      vehiclesAlarms: vehicleAlarmsReducer,
      vehiclesActiveAlarms: alarmsReducer,
      remoteAssistances: assistancesReducer,
      remoteAssistancesCustomer: assistancesCustomerReducer,
    }, { metaReducers }),
    EffectsModule.forRoot([
      CommonEffects,
      TractorDetailEffects,
      MaintenanceEffects,
      GeoviewEffects,
      GeoviewCustomerEffects,
      SbdEffects,
      UserDashboardEffects,
      AlarmEffects,
      NotificationsEffects,
      UserTractorsEffects,
      VehicleAlarmsEffectsEffects,
      ActiveAlarmEffects,
      AssistancesEffects,
      AssistancesCustomerEffects
    ]),
    FontAwesomeModule,
  ],
  providers: [
    RoleGuard,
    GroupsGuard,
    ScreenTrackingService,
    PerformanceMonitoringService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    {
      provide: API_BASE_URL,
      useFactory: getBaseUrl,
    },
    {
      provide: API_NOTIFICATION_BASE_URL,
      useFactory: getNotificationApiUrl,
    },
    {
      provide: API_AGRIROUTER_BASE_URL,
      useFactory: getAgrirouterApiUrl,
    },
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
}

// AOT compilation support
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function getBaseUrl(): string {
  return environment.apiURLV2;
}

export function getNotificationApiUrl(): string {
  return environment.notificationApiURL;
}

export function getAgrirouterApiUrl(): string {
  return environment.agrirouterApiURLV2;
}
