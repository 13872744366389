<!-- <div class='map'
     leaflet
     [leafletOptions]='options'
     (leafletMapReady)='onMapReady($event)'
>
</div> -->
<div class='map'
     leaflet
     [leafletOptions]='options'
     (leafletMapReady)='onMapReady($event)'
>
</div>
