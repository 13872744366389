import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap } from 'rxjs/operators';
import * as fromActions from './actions';
import { Store } from '@ngrx/store';
import { Sort } from '@angular/material/sort';
import { selectUser } from '@redux/index';
import { NotificationService, PushNotificationService } from '@services/notification-api.service';
import { promiseWrapper } from '@shared/_funtions/promiseWarapper.function';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '@env';

@Injectable()
export class NotificationsEffects {
  public $getSettings = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getNotificationsSettings),
      switchMap(async () => {
        const settings = await promiseWrapper(this.notificationService.getUserNotificationPreferences());
        return [fromActions.setNotificationsSettings({ settings })];
      }),
      switchMap((actions) => [...actions]),
    );
  });

  public $setSettings = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.updateNotificationsSettings),
      tap(() => this.store.dispatch(fromActions.setNotificationsLoading({ value: true }))),
      switchMap(async (action) => {
        await promiseWrapper(this.notificationService.updateUserNotificationPreferences(action.settings));
        return [fromActions.setNotificationsSettings(action), fromActions.setNotificationsLoading({ value: false })];
      }),
      switchMap((actions) => [...actions]),
    );
  });

  private pageSize: number;
  private pageIndex: number;
  private filters: any;

  $counter = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getNotificationsCounter),
      switchMap(async () => {
        const count = await promiseWrapper(this.pushNotificationService.notReadCounter());
        console.log('count', count);
        return [fromActions.setNotificationsCounter({ count: (count as any).notRead })];
      }),
      switchMap((actions) => [...actions]),
    );
  });
  hubConnection: any;
  public $setFilters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.setNotificationsFilters),
      tap(action => {
        this.filters = action.filters;
      }),
      switchMap((actions) => [fromActions.getNotificationsList({ pageIndex: 1, pageSize: this.pageSize || 10 })]),
    );
  });

  // Effect for marking notification as read
  public $markAsRead = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.markNotificationAsRead),
      tap(() => this.store.dispatch(fromActions.setNotificationsLoading({ value: true }))),
      switchMap(action =>
        this.pushNotificationService.markAsRead(action.id).pipe(
          map(() => fromActions.getNotificationsCounter()),
        ),
      ),
    );
  });

  // Effect for marking all notifications as read
  public $markAllAsRead = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.markAllNotificationsAsRead),
      tap(() => this.store.dispatch(fromActions.setNotificationsLoading({ value: true }))),
      switchMap(() =>
        this.pushNotificationService.markAllAsRead().pipe(
          map(() => fromActions.getNotificationsCounter()),
        ),
      ),
    );
  });

  private sort: Sort;
  public getData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.getNotificationsList),
      tap(action => {
        this.pageIndex = action.pageIndex;
        this.pageSize = action.pageSize;
        this.sort = action.sort || {
          active: 'time',
          direction: 'desc',
        };
      }),
      tap(() => this.store.dispatch(fromActions.setNotificationsLoading({ value: true }))),
      switchMap(() => {
        return this.pushNotificationService.getPushNotifications(undefined, this.pageIndex, this.pageSize).pipe(
          map(response => {

            return fromActions.setNotificationsList({
              notifications: response.items,
              page: this.pageIndex,
              pageSize: this.pageSize,
              totalNumber: response.totalItems,
            });
          }),
          tap(() => this.store.dispatch(fromActions.setNotificationsLoading({ value: false }))),
        );
      }),
    );
  });
  public $obtainNotificationsJwt = createEffect(() => this.actions$.pipe(
    ofType(fromActions.obtainNotificationsJwt),
    tap(async () => {
      const accessToken = await promiseWrapper(this.auth.getAccessTokenSilently(),
      );
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.notificationWsUrl}?access_token=${accessToken}`)
        .withAutomaticReconnect()
        .build();
      this.hubConnection.on('ReceiveNotification', () => {
        this.store.dispatch(fromActions.getNotificationsCounter());
      });
      const startConnection = async () => {
        try {
          await this.hubConnection.start();
        } catch (err) {
          setTimeout(startConnection, 5000);
        }
      };
      this.hubConnection.onclose(() => {
        startConnection();
      });
      // startConnection();
    }),
  ), { dispatch: false });
  private language: string;
  private user: any;

  constructor(
    private actions$: Actions,
    private store: Store,
    private auth: AuthService,
    private notificationService: NotificationService,
    private pushNotificationService: PushNotificationService,
  ) {
    this.pageIndex = undefined;
    this.pageSize = undefined;

    this.store.select(selectUser).subscribe(user => {
      if (!user?.user_metadata) {
        return;
      }
      this.user = user;
      this.language = user?.user_metadata?.language || 'en';
    });
  }
}
