<div class="notification-popup-container">
  <div class="notification-icon" (click)="toggle()">
    <i class='fa-solid fa-bell'></i>
    <span *ngIf="unreadCount > 0" class="notification-badge">
      {{ unreadCount > 99 ? '99+' : unreadCount }}
    </span>
  </div>

  <div *ngIf="isOpen" class="notification-panel">
    <div class="notification-header">
      <h3>{{ 'Notifications.Title' | translate }}</h3>
      <div class='action-row'>
        <div (click)='clearAll()'
             [ngClass]='{disabled: notifications.length === 0}'
             class='action-icon red'>
          <i class='fa-solid fa-trash'></i>
        </div>
        <div (click)='markAllAsRead()' [ngClass]='{disabled: unreadCount === 0}'
             class='action-icon'>
          <i class='fa-solid fa-envelope-open'></i>
        </div>

        <div (click)='goToSettings()'
             class='action-icon'>
          <i class='fa-solid fa-cog'></i>
        </div>
      </div>
    </div>

    <div #notificationContainer
         class='notification-list'>
      <div *ngIf="notifications.length === 0 && !isLoading" class="no-notifications">
        {{ 'Notifications.NoNotifications' | translate }}
      </div>

      <div *uiScroll='let notification of notifications;let index=index'
           class="notification-item"
           [class.unread]='!notification.isRead'
           (click)="markAsRead(notification)">
        <div class="notification-content">
          <h4>{{ notification.type }}</h4>
          <p>{{ notification.description }}</p>
          <small>{{ notification.time | date:'medium' }}</small>
        </div>
        <div class='notification-actions'>
          <div (click)='openNotification(notification)'
               class='action-icon'>
            <i class='fa-solid fa-arrow-up-right'></i>
          </div>
          <div (click)='deleteNotification(notification)'
               class='action-icon red'>
            <i class='fa-solid fa-trash'></i>
          </div>

        </div>
      </div>

      <div *ngIf="isLoading" class="loading-spinner">
        <i class="fa-solid fa-spinner fa-spin"></i>
      </div>
    </div>
  </div>
</div>
