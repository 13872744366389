<div>
  <app-equip-form *ngIf="showForm" style="max-width: 22em;"></app-equip-form>
  <div *ngIf="!showForm">
    <app-equip-card [equip]="equip" [linked]="true" [editable]="false" style="display: block; margin: 1em;"></app-equip-card>
    <div class="grid-container">
      <div class="grid-item">
        <div class="item-title">{{'EquipManagement.Fields.WorkedHours' | translate }}</div>
        <div class='content'>
        <div class='chart-container' style="margin-top: 2em;">
          <canvas #workedHoursChart></canvas>
        </div>
      </div>
      </div>
      <div class="grid-item map-item">
        <div class="item-title">{{'EquipManagement.Fields.Map' | translate }}</div>
        <app-equip-map *ngIf='lastPosition' [position]='lastPosition'
               class='map-element'></app-equip-map>
      </div>
      <div  class="grid-item photo-item">
        <img [src]="getImageSrc()" alt=""
          style="max-width: calc(100% + 2.2rem);
          margin: -1.1rem;
          width: calc(100% + 2.2rem);">
      </div>
      <div class="grid-item form-item">
        <div class="item-title">{{'EquipManagement.Fields.Data' | translate }}</div>
        <app-equip-form [implement]="equip" [inCard]="true"></app-equip-form>
      </div>
    </div>
  </div>
</div>
