import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectIsNotificationsLoading,
  selectNotifications,
  selectNotificationsPage,
  selectNotificationsPageSize,
  selectNotificationsTotalNumber,
  selectNotificationsUnreadCount,
} from '@redux/modules/notifications';
import { Datasource } from 'ngx-ui-scroll';
import {
  getNotificationsCounter,
  getNotificationsList,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  obtainNotificationsJwt,
} from '@redux/modules/notifications/actions';
import { Router } from '@angular/router';
import { PushNotificationDto, PushNotificationService } from '@services/notification-api.service';


@Component({
  selector: 'app-notification-popup',
  templateUrl: './notification-popup.component.html',
  styleUrls: ['./notification-popup.component.scss'],
})
export class NotificationPopupComponent implements OnInit {
  @Input() subscriberId: string;
  @ViewChild('notificationContainer') notificationContainer: ElementRef;

  notifications;
  isLoading = false;
  page = 1;
  pageSize = 10;
  totalNumber = 0;
  hasMoreNotifications = true;
  isOpen = false;
  unreadCount = 0;
  hasUnread = false;
  success;
  // toggle between static data and API


  // Uncomment this to switch to Redux

  constructor(
    private store: Store,
    private elementRef: ElementRef,
    private router: Router,
    private notificationService: PushNotificationService,
  ) {
    this.notifications = this.instantiateNotifications();
  }

  ngOnInit() {
    this.store.dispatch(getNotificationsCounter());
    this.store.dispatch(obtainNotificationsJwt());
    this.store.select(selectNotificationsUnreadCount).subscribe(unreadCount => {
      this.unreadCount = unreadCount;
      this.hasUnread = unreadCount > 0;
      if (this.isOpen) {
        this.notifications.adapter.reload(0);
      }
    });
    // Uncomment this to use Redux

    // Subscribe to notifications data from the store
    this.store.select(selectNotifications).subscribe(notifications => {
      // this.vehicles = tractors;
      console.log('notifications', notifications);
      this.success?.(notifications);
      this.isLoading = false;
    });

    // Subscribe to loading state
    this.store.select(selectIsNotificationsLoading).subscribe(isLoading => {
      this.isLoading = isLoading;
    });

    // Subscribe to pagination information
    this.store.select(selectNotificationsPage).subscribe(page => {
      this.page = page || 1;
    });

    this.store.select(selectNotificationsPageSize).subscribe(pageSize => {
      this.pageSize = pageSize || 10;
    });

    this.store.select(selectNotificationsTotalNumber).subscribe(totalNumber => {
      this.totalNumber = totalNumber || 0;
      this.hasMoreNotifications = this.notifications.length < this.totalNumber;
    });

    this.store.dispatch(getNotificationsList({
      pageIndex: 1,
      pageSize: 2,

    }));
  }

  toggle() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.notifications.adapter.reload(0);
    }
  }

  markAsRead(notification: PushNotificationDto) {
    if (!notification.isRead) {

      this.store.dispatch(markNotificationAsRead({ id: notification.id }));
    }


    // Uncomment to use Redux

    // Dispatch the action to mark notification as read

  }

  openNotification(notification: PushNotificationDto) {
    switch (notification.type) {

      case 'alarm':
      case 'maintenance':
        this.router.navigate([`/portal/tractor-mgmt/tractor-detail/${notification.vin}`]);
        break;
      default:
        // Do something
        break;

    }
  }

  deleteNotification(notification: PushNotificationDto) {
    this.notificationService.delete(notification.id).subscribe(() => {

      this.store.dispatch(getNotificationsCounter());
    });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target) && this.isOpen) {
      this.isOpen = false;
    }
  }

  markAllAsRead() {

    // Uncomment to use Redux
    // Dispatch the action to mark all notifications as read
    this.store.dispatch(markAllNotificationsAsRead());
  }

  clearAll() {
    this.notificationService.deleteAll().subscribe(() => {
      this.store.dispatch(getNotificationsCounter());
    });
  }

  goToSettings() {
    this.router.navigate(['/portal/notifications/settings']);
  }

  private instantiateNotifications() {
    return new Datasource({
      get: (index, count, success, fail) => {
        console.log('get: ' + index + ' ' + count);
        console.log('totalNumber: ' + this.totalNumber);
        if (this.totalNumber > 0 && ~~(index / count) + 1 <= ~~(this.totalNumber / this.pageSize) + 1) {
          this.store.dispatch(getNotificationsList({
            pageIndex: index === 0 ? 1 : ~~(index / count) + 1,
            pageSize: count,
          }));
        }
        this.success = success;
        if (~~(index / count) + 1 > ~~(this.totalNumber / this.pageSize) + 1) {
          fail('');
        }

      },
      settings: {
        startIndex: 0,
        bufferSize: 10,
        minIndex: 0,
        padding: 0.5,

      },
    });
  }


  private updateUnreadStatus() {
    const unreadNotifications = this.notifications.filter(n => !n.read);
    this.unreadCount = unreadNotifications.length;
    this.hasUnread = unreadNotifications.length > 0;
  }
}
