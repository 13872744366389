<div class='sidebar-container'>
  <div [ngClass]="{'hidden': menuOpen === false}"
       class='logo-image d-flex justify-content-start align-items-center'>
    <img (click)='clickToggleMenu()' alt='Logo' class='logo' src='../../../../assets/logo2.png'
         style='cursor: pointer;'>
  </div>
  <!--     User Profile -->
  <div *ngIf='user' class='header-user-container'>
    <div class='header-user-icon' (click)='openUserDialog()'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="user.name + ' (' + userRole + ')'"
         matTooltipPosition='right'>
      <div [style.backgroundImage]="'url('+ user.picture  +')'" class='header-user-img'></div>
    </div>
    <div class='header-user-info'>
      <div class='header-user-name'>{{ user.name }}</div>
      <div class='header-user-role'>{{ userRole }}</div>
    </div>
  </div>
  <div class='d-flex profile'>
    <!--     Dealer Selection -->
  </div>
  <nav id='sidebar-wrapper' role='navigation'>
     <div *appUserRole='[ Role.VmCustomer ]'
          [ngClass]="{ 'sidebar-item-active': router.url.includes('geoview-customer') }"
          class='d-flex align-items-baseline sidebar-item'
     >
     <a routerLink='/portal/geoview-customer'
          [matTooltipDisabled]='menuOpen === true'
          [matTooltip]="'Sidebar.Overview' | translate"
          matTooltipPosition='right'
          routerLinkActive='active'>
          <i class="fa-solid fa-eye nav-icon menu-icon"></i>
       </a>
       <a class='nav-link' routerLink='/portal/geoview-customer'
          routerLinkActive='active'>{{ 'Sidebar.Overview' | translate }}</a>
     </div>
     <div *appUserRole='[ Role.VmCustomer ]'
          [ngClass]="{ 'sidebar-item-active': router.url.includes('dashboard') }"
          class='d-flex align-items-baseline sidebar-item'
     >
     <a routerLink='/portal/dashboard'
          [matTooltipDisabled]='menuOpen === true'
          [matTooltip]="'Sidebar.Dashboard' | translate"
          matTooltipPosition='right'
          routerLinkActive='active'>
          <i class="fa-solid fa-gauge nav-icon menu-icon"></i>
       </a>
       <a class='nav-link' routerLink='/portal/dashboard'
          routerLinkActive='active'>{{ 'Sidebar.Dashboard' | translate }}</a>
     </div>
    <div *appUserRole='[ Role.Customer , Role.VmCustomer ]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('tractor-mgmt') }"
         class='d-flex align-items-baseline sidebar-item'
    >
      <a routerLink='/portal/tractor-mgmt'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.MyFleet' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'>
        <svg-icon src='assets/icons/tractor.svg'></svg-icon>
      </a>
      <a class='nav-link' routerLink='/portal/tractor-mgmt'
         routerLinkActive='active'>{{ 'Sidebar.MyFleet' | translate }}</a>
    </div>

    <div *appUserRole='[ Role.Dealer, Role.Subsidiary, Role.Factory ]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('geoview') || router.url.includes('tractor-detail') }"
         class='d-flex align-items-baseline sidebar-item'
    >
      <a routerLink='/portal/geoview'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.MyFleet' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'>
        <svg-icon src='assets/icons/tractor.svg'></svg-icon>
      </a>
      <a class='nav-link' routerLink='/portal/geoview' routerLinkActive='active'>{{ 'Sidebar.MyFleet' | translate }}</a>
    </div>

    <div *appUserRole='[ Role.Customer , Role.VmCustomer]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('connections') }"
         class='d-flex align-items-baseline sidebar-item'
    >
    <a routerLink='/portal/connections'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.DataMgmt' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'>
        <svg-icon src='assets/icons/connections.svg'></svg-icon>
      </a>
      <a class='nav-link' routerLink='/portal/connections'
         routerLinkActive='active'>{{ 'Sidebar.DataMgmt' | translate }}</a>
    </div>
    <div *appUserRole='[ Role.VmCustomer ]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('equip-mgmt') }"
         class='d-flex align-items-baseline sidebar-item'
    >
    <a routerLink='/portal/equip-mgmt'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.EquipMgmt' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'>
         <i class="fa-solid fa-toolbox nav-icon menu-icon"></i>
      </a>
      <a class='nav-link' routerLink='/portal/equip-mgmt'
         routerLinkActive='active'>{{ 'Sidebar.EquipMgmt' | translate }}</a>
    </div>
    <div *appUserRole='[ Role.VmCustomer ]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('fields-mgmt') }"
         class='d-flex align-items-baseline sidebar-item'
    >
    <a routerLink='/portal/fields-mgmt'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.FieldsMgmt' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'>
        <svg-icon src='assets/icons/field_mgmt_2.svg'></svg-icon>
      </a>
      <a class='nav-link' routerLink='/portal/fields-mgmt'
         routerLinkActive='active'>{{ 'Sidebar.FieldsMgmt' | translate }}</a>
    </div>
    <div *appUserRole='[ Role.VmCustomer ]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('variable-field-estimation') }"
         class='d-flex align-items-baseline sidebar-item'
    >
    <a routerLink='/portal/variable-field-estimation'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.VariableFieldEstimation' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'>
         <i class="fa-solid fa-square-dashed nav-icon menu-icon"
          style="font-size: 1.15em;
               margin-top: 2px;
               margin-left: 0;"></i>
      </a>
      <a class='nav-link' routerLink='/portal/variable-field-estimation'
         routerLinkActive='active'>{{ 'Sidebar.VariableFieldEstimation' | translate }}</a>
    </div>

    <!-- <div (click)='openNotifications($event)' *appUserRole='[ Role.Dealer, Role.Subsidiary, Role.Factory  ]'
         class='d-flex align-items-baseline sidebar-item'
    >
      <a
        [matTooltipDisabled]='menuOpen === true'
        matTooltipPosition='right'
        [matTooltip]="'Sidebar.Notifications' | translate"
        routerLinkActive='active'
        class="sidebar-icon">
        <notification-center-component
          (click)='openNotifications($event)' [applicationIdentifier]='applicationIdentifier'
          [backendUrl]='novuUrl' [popoverConfig]='popoverConfig'
          [showUserPreferences]='false'
          [socketUrl]='novuWsUrl'
          [subscriberId]='subscriberId'
          colorScheme='light'
          id='notificationCenter'
        ></notification-center-component>
      </a>

      <a (click)='openNotifications($event)' class='nav-link'
         routerLinkActive='active'>{{ 'Sidebar.Notifications' | translate }}</a>
    </div> -->

    <div *appUserRole='[ Role.Dealer, Role.Subsidiary, Role.Factory  ]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('alarms') && !router.url.includes('tractor-detail') }"
         class='d-flex align-items-baseline sidebar-item'
    >
      <a routerLink='/portal/alarms'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.Alarms' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'
         class="sidebar-icon">
        <svg-icon src='assets/icons/tractor.svg'></svg-icon>
      </a>
      <a class='nav-link' routerLink='/portal/alarms'
         routerLinkActive='active'>{{ 'Sidebar.Alarms' | translate }}</a>
    </div>

    <div *appUserRole='[ Role.Dealer, Role.Subsidiary, Role.Factory  ]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('maintenance') }"
         class='d-flex align-items-baseline sidebar-item'
    >
      <a routerLink='/portal/maintenance'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.Maintenance' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'
         class="sidebar-icon">
        <svg-icon src='assets/icons/manteinance.svg'></svg-icon>
      </a>
      <a class='nav-link' routerLink='/portal/maintenance'
         routerLinkActive='active'>{{ 'Sidebar.Maintenance' | translate }}</a>
    </div>
    <ng-container *ngIf='user?.isMaster'
    >
      <div
         [ngClass]="{ 'sidebar-item-active': router.url.includes('groups') }"
         class='d-flex align-items-baseline sidebar-item'
    >
      <a routerLink='/portal/groups-mgmt'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.GroupsMgmt' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'
         class="sidebar-icon">
         <i class="fa-solid fa-users nav-icon menu-icon"></i>
      </a>
      <a class='nav-link' routerLink='/portal/groups-mgmt'
         routerLinkActive='active'>{{ 'Sidebar.GroupsMgmt' | translate }}</a>
    </div>
    </ng-container>
    <div *appUserRole='[ Role.Factory  ]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('service-breakdown') }"
         class='d-flex align-items-baseline sidebar-item'
    >
      <a routerLink='/portal/service-breakdown'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.ServiceBreakdown' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'
         class="sidebar-icon">
        <svg-icon src='assets/icons/service-breakdown.svg'></svg-icon>
      </a>
      <a class='nav-link' routerLink='/portal/service-breakdown'
         routerLinkActive='active'>{{ 'Sidebar.ServiceBreakdown' | translate }}</a>
    </div>
    <div *appUserRole='[ Role.Admin ]'
         [ngClass]="{ 'sidebar-item-active': router.url.includes('admin-mgmt') }"
         class='d-flex align-items-baseline sidebar-item'
    >
    <a routerLink='/portal/admin-mgmt'
         [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.AdminMgmt' | translate"
         matTooltipPosition='right'
         routerLinkActive='active'>
         <svg-icon src='assets/icons/users.svg'></svg-icon>
      </a>
      <a class='nav-link' routerLink='/portal/admin-mgmt'
         routerLinkActive='active'>{{ 'Sidebar.AdminMgmt' | translate }}</a>
    </div>
<!--    <div *appUserRole='[ Role.Dealer ]'-->
<!--         [ngClass]="{ 'sidebar-item-active': router.url.includes('remote-assistances') && !router.url.includes('remote-assistances-customer') }"-->
<!--         class='d-flex align-items-baseline sidebar-item'-->
<!--    >-->
<!--    <a routerLink='/portal/remote-assistances'-->
<!--         [matTooltipDisabled]='menuOpen === true'-->
<!--         [matTooltip]="'Sidebar.RemoteAssistances' | translate"-->
<!--         matTooltipPosition='right'-->
<!--         routerLinkActive='active'>-->
<!--         <svg-icon src='assets/icons/wrench-svgrepo-com.svg'></svg-icon>-->
<!--      </a>-->
<!--      <a class='nav-link' routerLink='/portal/remote-assistances'-->
<!--         routerLinkActive='active'>{{ 'Sidebar.RemoteAssistances' | translate }}</a>-->
<!--    </div>-->
<!--    <div *appUserRole='[ Role.Customer ]'-->
<!--         [ngClass]="{ 'sidebar-item-active': router.url.includes('remote-assistances-customer') }"-->
<!--         class='d-flex align-items-baseline sidebar-item'-->
<!--    >-->
<!--    <a routerLink='/portal/remote-assistances-customer'-->
<!--         [matTooltipDisabled]='menuOpen === true'-->
<!--         [matTooltip]="'Sidebar.RemoteAssistances' | translate"-->
<!--         matTooltipPosition='right'-->
<!--         routerLinkActive='active'>-->
<!--         <svg-icon src='assets/icons/wrench-svgrepo-com.svg'></svg-icon>-->
<!--      </a>-->
<!--      <a class='nav-link' routerLink='/portal/remote-assistances-customer'-->
<!--         routerLinkActive='active'>{{ 'Sidebar.RemoteAssistances' | translate }}</a>-->
<!--    </div>-->

  </nav>

  <div class='conn-services-menu'>
    <div class='conn-services'>
      <p class='conn-services-title'>{{ 'Sidebar.Sections.ConnServices' | translate }}</p>
      <mat-icon class='conn-services-icon'>launch</mat-icon>
    </div>
    <div class='d-flex align-items-baseline sidebar-item'>
      <a (click)="openLink('https://www.sdf-fleetmanagement.com/')" [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.FleetMgmt' | translate"
         class='nav-link nav-link-icon'
         matTooltipPosition='right'>
        <svg-icon [applyClass]='true'
                  class='sidebar-item-icon-fleet-mgmt' src='assets/icons/fleet_mgmt.svg'></svg-icon>
      </a>
      <a (click)="openLink('https://www.sdf-fleetmanagement.com/')" class='nav-link nav-link-text'>{{
          'Sidebar.FleetMgmt' |
            translate
        }}</a>
    </div>
    <div *appUserRole='[ Role.Customer ]'
         class='d-flex align-items-baseline sidebar-item'>
      <a (click)="openLink('https://farm.sdfgroup.com/')" [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.FarmMgmt' | translate"
         class='nav-link nav-link-icon'
         matTooltipPosition='right'>
        <svg-icon src='assets/icons/farm_mgmt.svg'></svg-icon>
      </a>
      <a (click)="openLink('https://farm.sdfgroup.com/')"
         class='nav-link nav-link-text'>{{ 'Sidebar.FarmMgmt' | translate }}</a>
    </div>
    <div *appUserRole='[ Role.Customer ]'
         class='d-flex align-items-baseline sidebar-item'>
      <a (click)="openLink('https://pathplanner.sdfgroup.com')" [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.PathPlanner' | translate"
         class='nav-link nav-link-icon'
         matTooltipPosition='right'>
        <svg-icon src='assets/icons/path_planner.svg'></svg-icon>
      </a>
      <a (click)="openLink('https://pathplanner.sdfgroup.com/')"
         class='nav-link nav-link-text'>{{ 'Sidebar.PathPlanner' | translate }}</a>
    </div>
    <!--    <div class="d-flex align-items-baseline sidebar-item">-->
    <!--      <svg-icon src="assets/icons/field_mgmt.svg"></svg-icon>-->
    <!--      <a class="nav-link" (click)="openLink('http://sdf.ibfservizi.it/')">{{'Sidebar.FieldMgmt' | translate}}</a>-->
    <!--    </div>-->
    <div *appUserRole='[ Role.Customer ]'
         class='d-flex align-items-baseline sidebar-item'>
      <a (click)="openLink('https://store.sdfgroup.com')" [matTooltipDisabled]='menuOpen === true'
         [matTooltip]="'Sidebar.Store' | translate"
         class='nav-link nav-link-icon'
         matTooltipPosition='right'>
        <svg-icon src='assets/icons/store.svg'></svg-icon>
      </a>
      <a (click)="openLink('https://store.sdfgroup.com')"
         class='nav-link nav-link-text'>{{ 'Sidebar.Store' | translate }}</a>
    </div>
  </div>
</div>
