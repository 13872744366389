export class Tractor {
  owner?: string;
  vin?: string;
  user_id?: string;
  name?: string;
  userVehiclePhoto?: string;
  purchase_date?: string;
  // details?: Details;
  brand?: string;
  model?: string;
  maintenance?: Maintenance;
  prod_year?: Date;
  warranty_start?: Date;
}

// class Details {
//   model?: string;
//   brand?: string;
//   license_id?: string;
//   horsepower?: string;
//   prod_year?: number;
//   ureaLevel?: number;
//   fuelLevel?: number;
//   batteryLevel?: number;
//   licensePlate?: string;
//
//   //fuel_type?: string;
//   //transmission_serial?: string;
//   //ctm_serial?: string;
//   //transmission?: string;
// }

class Maintenance {
  next_control_remaining_hours?: number;
  last_control_date?: string;
  next_control_date?: string;
  last_revision_date?: string;
  next_revision_date?: string;
  last_engine_hours?: number;
  next_control_hours?: number;
  current_engine_hours?: number;
}

export class Alarm {
  ecu?: string
  fmi?: number;
  spn?: number;
  alarmCount?: number;
  source?: string;
  description?: string;
  severity?: string;
  engineHours?: number;
  lastUpdateDateTime?: string;
}

export enum DataTraffic {
  Ctm = 0,
  Btm = 1,
  SdfCtm = 2,
  None = 3,
}
