<div class='header-container d-flex justify-content-between align-items-center'>
  <!-- <div class="logo-image d-flex justify-content-start align-items-center"
    [ngClass]="{'rotate': menuOpen === false}" (click)="clickToggleMenu()">
    <img alt='Logo' class='logo' src='../../../../assets/sdf_logo.png'>
  </div> -->
  <div class='header-title' *ngIf="router.url.includes('home')">
    <div>{{'Header.Home' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('portal/geoview') && !router.url.includes('portal/geoview-customer')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.Fleet' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('portal/geoview-customer')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.Overview' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('maintenance')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.MaintenanceMgmt' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('groups')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.GroupsMgmt' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('tractor-mgmt/tractors')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.Fleet' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('tractor-mgmt/tractor-detail') && !router.url.includes('alarms/history')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.TractorDetail' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('tractor-mgmt/tractor-map')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.TractorMap' | translate}}</div>
  </div>
  <div *ngIf="router.url.includes('notifications/settings')" class='header-title'>
    <div>
      <span (click)='back()' style='cursor: pointer;'><i class='fas fa-arrow-left' style='font-size: .8em;'></i></span>
      {{'Header.NotificationsSettings' | translate}}</div>
  </div>
  <div *ngIf="router.url.includes('notifications') && !router.url.includes('settings')" class='header-title'>
    <div>
      <span (click)='back()' style='cursor: pointer;'><i class='fas fa-arrow-left' style='font-size: .8em;'></i></span>
      {{'Header.Notifications' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('alarms/history')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.TractorDetailAlarmsHistory' | translate}}</div>
  </div>
  <div *ngIf="router.url.includes('alarms')&& !router.url.includes('alarms/history')" class='header-title'>
    <div>
      <span (click)='back()' style='cursor: pointer;'><i class='fas fa-arrow-left' style='font-size: .8em;'></i></span>
      {{ 'Header.ActiveAlarmsList' | translate }}
    </div>
  </div>
  <div *ngIf="router.url.includes('service-breakdown')" class='header-title'>
    <div>
      <span (click)='back()' style='cursor: pointer;'><i class='fas fa-arrow-left' style='font-size: .8em;'></i></span>
      {{ 'Header.ServiceBreakdown' | translate }}
    </div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('connections')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.DataMgmt' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('dashboard')">
    <div>
      <!-- <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span> -->
      {{'Header.Dashboard' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('fields-mgmt')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.FieldsMgmt' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('variable-field-estimation')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.VariableFieldEstimation' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('admin-mgmt')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.AdminMgmt' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('equip-mgmt')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.EquipMgmt' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('user-mgmt')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.UserMgmt' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('dealer-mgmt')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.DealerMgmt' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('remote-assistances') && !router.url.includes('remote-assistances-customer') && !router.url.includes('assistance-detail')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.RemoteAssistances' | translate}}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('remote-assistances') && !router.url.includes('remote-assistances-customer') && router.url.includes('assistance-detail')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.Assistance' | translate}} #{{ router.url.split('/').pop() }}</div>
  </div>
  <div class='header-title' *ngIf="router.url.includes('remote-assistances-customer') && !router.url.includes('assistance-detail')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.RemoteAssistances' | translate}}</div>
  </div>
  <ng-container *ngIf="!router.url.includes('notifications/settings')">
    <div *appUserRole='[ Role.Dealer, Role.Subsidiary, Role.Factory  ]'
         class='notification-container'>
      <a
        [matTooltip]="'Sidebar.Notifications' | translate"
        class='sidebar-icon'
        matTooltipPosition='right'
        routerLinkActive='active'>
        <app-notification-popup></app-notification-popup>
      </a>
      <!-- <a (click)="notificationPopup.toggle()" class='nav-link'
           routerLinkActive='active'>{{ 'Sidebar.Notifications' | translate }}</a> -->
    </div>
  </ng-container>
  <div class='header-title' *ngIf="router.url.includes('remote-assistances-customer') && router.url.includes('assistance-detail')">
    <div>
      <span (click)="back()" style="cursor: pointer;"><i class="fas fa-arrow-left" style="font-size: .8em;"></i></span>
      {{'Header.Assistance' | translate}} #{{ router.url.split('/').pop() }}</div>
  </div>
</div>
