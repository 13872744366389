import { Component, Inject, OnInit } from '@angular/core';
import { environment } from '@env';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { UserRole } from '@models/user';
import { ManageUserFormComponent } from '@portal/user-mgmt/add-user-form/manage-user-form.component';
import { LoaderService } from '@services/loader.service';
import { UserService } from '@services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { ChangePasswordComponent } from '@portal/user-mgmt/change-password/change-password.component';
import { resetState, setUser } from '@redux/actions';
import { selectUser } from '@redux/index';
import { filter } from 'rxjs/operators';
import { Auth0UserDetailDto } from '@shared/_services/api.service';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './user-dialog.component.html',
  styleUrls: ['./user-dialog.component.scss'],
})
export class UserDialogComponent implements OnInit {
  version = environment.Version;
  user: Auth0UserDetailDto;

  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    private auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private userService: UserService,
    public store: Store,
    private translate: TranslateService,
    @Inject(DOCUMENT) private doc: Document,
  ) {
  }

  ngOnInit() {
    this.store.select(selectUser).pipe(filter(user => user != null)).subscribe(user => {
      this.user = user;
    });
  }

  logout() {
    // this.translate.use('en').subscribe(() => {
    this.dialogRef.close();
    this.store.dispatch(resetState());
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
    // })
  }

  protected readonly Role = UserRole;
  editProfile() {

    const userInfo = this.user;
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ManageUserFormComponent, {
      width: '915px',
      minWidth: '915px',
      height: '750px',
      panelClass: 'edit-user-modal',
      data: { title: this.translate.instant('UserActions.EditProfile'), action: 'edit', item: userInfo },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((updatedUser: Auth0UserDetailDto) => {
      if (updatedUser) {
        this.loaderService.show();
        this.userService.updateUserInfo(updatedUser)
          .subscribe(
            (response) => {
              this.store.dispatch(setUser(response));
              this.translate.use(response.user_metadata.language).subscribe(() => {
                this.loaderService.hide();
              });
            }, _ => {
              this.loaderService.hide();
            });
      }
    });
  }

  changePassword() {
    this.dialogRef.close();
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '500px',
      height: '350px',
      panelClass: 'edit-user-modal',
      data: { title: this.translate.instant('UserActions.ChangePassword') },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((updatedPassword: Auth0UserDetailDto) => {
      if (updatedPassword) {
        this.loaderService.show();
        this.userService.updateUserInfo(updatedPassword)
          .subscribe(
            () => {
              this.loaderService.hide();
            }, _ => {
              this.loaderService.hide();
            });

      }
    });
  }

  goToNotificationSettings() {
    this.dialogRef.close();

  }
}
