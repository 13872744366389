<div [class.hidden]="!show">
  <div class="loader-overlay">
    <div *ngIf="show" class="lds-hourglass">
      <svg
        width="150px"
        height="150px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        class="lds-stripe"
        style="background: none;"
      >
        <defs>
          <pattern
            patternUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="100"
            height="100"
            id="lds-stripe-patid-4c052fdeeefee"
          >
            <g transform="translate(20.3818 0)">
              <g transform="rotate(20 50 50) scale(1.2)">
                <rect
                  x="-20"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#ffffff"
                ></rect>
                <rect
                  x="-10"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#FA8C17"
                ></rect>
                <rect
                  x="0"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#ffffff"
                ></rect>
                <rect
                  x="10"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#FA8C17"
                ></rect>
                <rect
                  x="20"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#ffffff"
                ></rect>
                <rect
                  x="30"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#FA8C17"
                ></rect>
                <rect
                  x="40"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#ffffff"
                ></rect>
                <rect
                  x="50"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#FA8C17"
                ></rect>
                <rect
                  x="60"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#ffffff"
                ></rect>
                <rect
                  x="70"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#FA8C17"
                ></rect>
                <rect
                  x="80"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#ffffff"
                ></rect>
                <rect
                  x="90"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#FA8C17"
                ></rect>
                <rect
                  x="100"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#ffffff"
                ></rect>
                <rect
                  x="110"
                  y="-10"
                  width="10"
                  height="120"
                  fill="#ffffff"
                ></rect>
              </g>
              <animateTransform
                attributeName="transform"
                type="translate"
                values="0 0;26 0"
                keyTimes="0;1"
                repeatCount="indefinite"
                dur="1s"
              ></animateTransform>
            </g>
          </pattern>
        </defs>
        <rect
          rx="10"
          ry="10"
          x="10"
          y="40"
          stroke="#FA8C17"
          stroke-width="3"
          width="80"
          height="20"
          fill="url(#lds-stripe-patid-4c052fdeeefee)"
        ></rect>
      </svg>
    </div>
  </div>
</div>
