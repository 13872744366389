<div class="page-container" [ngClass]="inCard || allowSave ? 'in-card' : ''">
  <div class="form-wrapper">
    <div class="input-wrapper">
      <label for="entity-name">{{'EquipManagement.Fields.Name' | translate}}</label>
      <div class="input-with-button">
        <input type="text" id="entity-name" [(ngModel)]="implement.name" placeholder="{{'EquipManagement.Fields.Name' | translate}}"
          [disabled]="inCard">
      </div>
    </div>
    <div class="input-wrapper">
      <label for="entity-name">{{'EquipManagement.Fields.ChooseType' | translate}}</label>
      <select id='chooseType' matInput class="basic-input"
          placeholder="" type='text'
          [disabled]="inCard"
          [(ngModel)]="implement.type"
          (ngModelChange)="setType()">
          <option value="">{{'EquipManagement.Fields.ChooseType' | translate}}</option>
          <option *ngFor="let type of typesList" [value]="type.key">
              {{type.value}}
          </option>
      </select>
    </div>
    <div class="input-wrapper">
      <label for="entity-name">{{'EquipManagement.Fields.ChooseBrand' | translate}}</label>
      <input type="text" id="entity-brand" [(ngModel)]="implement.brand" placeholder="{{'EquipManagement.Fields.ChooseBrand' | translate}}"
          [disabled]="inCard">
      <!-- <select id='chooseBrand' matInput class="basic-input"
          placeholder="" type='text'
          [disabled]="inCard"
          [(ngModel)]="implement.brand"
          (ngModelChange)="setBrand()">
          <option value="">{{'EquipManagement.Fields.ChooseBrand' | translate}}</option>
          <option *ngFor="let brand of brandsList" [value]="brand.key">
              {{brand.value}}
          </option>
      </select> -->
    </div>
    <div class="input-wrapper">
      <label for="entity-name">{{'EquipManagement.Fields.ChooseModel' | translate}}</label>
      <input type="text" id="entity-brand" [(ngModel)]="implement.model" placeholder="{{'EquipManagement.Fields.ChooseModel' | translate}}"
          [disabled]="inCard">
      <!-- <select id='chooseModel' matInput class="basic-input"
          placeholder="" type='text'
          [disabled]="inCard"
          [(ngModel)]="implement.model"
          (ngModelChange)="setModel()">
          <option value="">{{'EquipManagement.Fields.ChooseModel' | translate}}</option>
          <option *ngFor="let model of filteredModels" [value]="model.key">
              {{model.value}}
          </option>
      </select> -->
    </div>
    <div class="input-wrapper">
      <label for="mac-address">{{'EquipManagement.Fields.MacAddress' | translate}}</label>
      <div class="input-with-button">
        <input type="text" id="mac-address" [(ngModel)]="implement.macAddress" placeholder="{{'EquipManagement.Fields.MacAddress' | translate}}"
          [disabled]="(id && id !== 'new') || inCard">
      </div>
    </div>
    <div class="input-wrapper">
      <label for="purchase-year">{{'EquipManagement.Fields.purchaseDate' | translate}}</label>
      <!-- <div class="input-with-button">
        <input type="text" id="purchase-year" [(ngModel)]="implement.purchaseDate" placeholder="{{'EquipManagement.Fields.purchaseDate' | translate}}"
          [disabled]="inCard">
      </div> -->
      <div class="input-with-button date-input">
        <mat-form-field class='dense flat full-width'>
          <input 
            [matDatepicker]='picker' 
            [(ngModel)]='implement.purchaseDate' 
            matInput 
            [disabled]="inCard">
            
          <mat-datepicker-toggle [for]='picker' matPrefix style='margin-right:1rem '>
            <i class='fa-thin fa-calendar-day' matDatepickerToggleIcon
               style='font-size: 26px; margin-bottom: 0.4em;'></i>
          </mat-datepicker-toggle>
          
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div class="page-container action-buttons">
  <!-- <button class="btn btn-negative sdf-button" (click)="onDeleteClick()"
      type="button">{{'UserMgmt.Dialogs.Actions.Delete' |
      translate}} <mat-icon class="icon">delete</mat-icon></button> -->
  <button *ngIf="!id || id === 'new'" class="btn btn-primary sdf-button" [disabled]="inCard" type="submit" (click)="onAssociate()"
      [disabled]="false">{{'UserMgmt.Dialogs.Actions.Associate' | translate}} <mat-icon class="icon">link</mat-icon></button>
  <button *ngIf="id && id !== 'new' && !inCard" class="btn btn-positive sdf-button" type="submit" (click)="onSave()"
      [disabled]="false">{{'UserMgmt.Dialogs.Actions.Save' | translate}} <mat-icon class="icon">save</mat-icon></button>
</div>
