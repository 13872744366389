<div class="add-account-dialog-content">
    <div class='header-title'>
    <div class='title-toolbar'>
        <div class="title-text">{{data.title}}</div>
        <div>
        <button (click)='onNoClick()' class='close-button' mat-icon-button>
            <mat-icon>close</mat-icon>
        </button>
        </div>
    </div>
    </div>
    <div class="add-account-container">
        <form class="d-flex flex-wrap" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()"
            autocomplete="off">
            <div class="input-wrapper">
                <div class="form-group-single">
                    <div class="form-group">
                        <label class="edit-label" for="password">
                            <!-- <span>
                                <mat-icon matTooltipClass="material-tooltip"
                                          [matTooltip]="passwordTooltip"
                                    placement="top">info</mat-icon>
                            </span> -->
                            <span>{{'UserForm.Fields.Password' | translate}}</span>
                        </label>
                        <div class="new-password-wrapper">
                            <input autocomplete="new-password" id="password" [type]="pwdVisible? 'text': 'password'"
                                formControlName="password" class="form-control col-11 basic-input"
                                [ngClass]="{ 'is-invalid': submitted && changePwdF.password.errors }" />
                            <mat-icon class="password-visibility selectable" *ngIf="pwdVisible" (click)="pwdVisible=false">visibility
                            </mat-icon>
                            <mat-icon class="password-visibility selectable" *ngIf="!pwdVisible" (click)="pwdVisible=true">
                                visibility_off
                            </mat-icon>
                        </div>
                        <div class="form-error">
                            <div *ngIf="submitted && changePwdF.password.errors && changePwdF.password.errors.pattern">
                                {{'UserForm.Errors.Pattern.Password' | translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group-single">
                    <div class="form-group">
                        <label class="edit-label" for="confirmPassword">{{'UserForm.Fields.ConfirmPwd' | translate}}</label>
                        <input autocomplete="off" id="confirmPassword" type="password" formControlName="confirmPassword"
                            class="form-control basic-input" [ngClass]="{ 'is-invalid': submitted && changePwdF.confirmPassword.errors }" />
                        <div class="form-error">
                            <div
                                *ngIf="submitted && changePwdF.confirmPassword.errors && changePwdF.confirmPassword.errors.NoPasswordMatch">
                                {{'UserForm.Errors.Pattern.ConfirmPwd' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>
            

            

            <div class="action-buttons">
                <button class="btn btn-secondary sdf-button" (click)="onNoClick()"
                    type="button">{{'UserMgmt.Dialogs.Actions.Cancel' |
                    translate}}</button>
                <button class="btn btn-primary sdf-button" type="submit"
                    [disabled]="!changePasswordForm.dirty">{{'UserMgmt.Dialogs.Actions.Save' | translate}}</button>
            </div>

        </form>
    </div>
</div>
