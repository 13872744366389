<div class="page-container">
  <div class="input-wrapper">
    <label for='entity-name'>{{ 'FieldsManagement.Fields.Name' | translate }}</label>
    <!-- <div *ngIf="!showRecalc">kkkkkk</div> -->
    <div *ngIf='field' class='input-with-button'>
      <input [(ngModel)]='field.name' id='entity-name' placeholder="{{'FieldsManagement.Fields.Name' | translate}}"
             type='text'>
      <button (click)='downloadFieldFile()' *ngIf='field.entityUpdateDateTime' [disabled]='false'
              class='btn btn-primary sdf-button'>{{ 'FieldsManagement.Actions.Download' | translate }} <i class='fa fa-download'></i>
      </button>
    </div>
  </div>
  <div class="keys" *ngIf="fieldBoundariesImported.length > 0">
    <div class="actual">
      <div class="circle"></div>
      {{'FieldsManagement.Fields.Actual' | translate}}
    </div>
    <div class="imported">
      <div class="circle"></div>
      {{'FieldsManagement.Fields.Recalculated' | translate}}
    </div>
  </div>
</div>
<div class="page-container body" *ngIf="field">
  <div class="details-wrapper">
    <div class="details">
      <div class="label">{{'FieldsManagement.Labels.Area' | translate}}:</div>
      <div class="value">
        {{ field?.area || '-' }} {{'FieldsManagement.Labels.Hectares' | translate}}
      </div>
      <div class="label">{{'FieldsManagement.Labels.LastUpdated' | translate}}:</div>
      <div class="value">
        {{ field?.entityUpdateDateTime | date:'dd/MM/yyyy HH:mm' || '-' }}
      </div>
      <div class="label">{{'FieldsManagement.Labels.LastImport' | translate}}:</div>
      <div class="value">
        {{ field?.importEntityUpdateDateTime | date:'dd/MM/yyyy HH:mm' || '-' }}
      </div>
      <div class="label">{{'FieldsManagement.Labels.Vins' | translate}}:</div>
      <div class="value">
        <div *ngIf='field.vehicles && field.vehicles.length > 0' class='vins'>
          <div *ngFor='let vin of field.vehicles' class='vin-circle'>
            <img [matTooltip]="vin.vin" [src]='vin.vehiclePhotoUrl' alt='{{ vin.vin }}' style="max-width: 100%;">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="map">
    <app-field-map *ngIf='field' [fieldBoundariesImported]='fieldBoundariesImported'
                   [fieldBoundaries]='fieldBoundaries' class='map-element'
      [editableMap]='editableMap'></app-field-map>
  </div>
</div>
<div class="page-container action-buttons">
  <button class="btn btn-negative sdf-button" (click)="onDeleteClick()"
      type="button">{{'UserMgmt.Dialogs.Actions.Delete' |
      translate}} <mat-icon class="icon">delete</mat-icon></button>
  <button *ngIf="fieldBoundariesImported.length <= 0" class="btn btn-primary sdf-button" type="submit" (click)="onSave()"
      [disabled]="false">{{'UserMgmt.Dialogs.Actions.Save' | translate}} <mat-icon class="icon">save</mat-icon></button>
  <button *ngIf="fieldBoundariesImported.length > 0" class="btn btn-primary sdf-button" type="submit" (click)="onSave()"
      [disabled]="false">{{'FieldsManagement.Actions.Confirm' | translate}} <mat-icon class="icon">check</mat-icon></button>
</div>
