import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PrivacyTermsService } from '@services/api.service';

@Component({
  selector: 'app-legal-pages',
  templateUrl: './legal-pages.component.html',
  styleUrls: ['./legal-pages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LegalPagesComponent implements OnInit {

  type;
  language;
  text = '';
  loadingData = true;
  sanitizedText: SafeHtml = '';

  constructor(
    private route: ActivatedRoute,
    private privacyTermsService: PrivacyTermsService,
    private sanitizer: DomSanitizer

) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.type = params.get('type');
      this.language = params.get('language');
      this.privacyTermsService.getPrivacyTerms(this.type, this.language).subscribe((response) => {
          // this.sanitizedText = this.sanitizer.bypassSecurityTrustHtml(response.data);
          this.sanitizedText = response.data
          this.loadingData = false;
        },
        (error) => {
          alert(error.error.error);
        });
    });
  }

}
