<div class="dialog-content">
    <div class='header-title'>
        <div class='title-toolbar'>
          <div class="title-text">{{data.title}}</div>
          <div>
            <button (click)='onNoClick()' class='close-button' mat-icon-button>
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
    </div>
    <div class="add-account-container">
        <!-- <div class="dialog-title" mat-dialog-title>{{data.title}}</div> -->
        <form class="d-flex flex-column" [formGroup]="userForm" (ngSubmit)="manageUser()" autocomplete="off">
            <div class="d-flex flex-wrap">
                <div class="form-group col-6" *ngIf="data.action==='create'">
                    <!--<mat-select formControlName="userType" disableRipple="true" (valueChange)="updateTypeForm($event)">
                        <mat-option *ngFor="let ut of userTypeList" [value]="ut.value">{{ut.name}}
                        </mat-option>
                    </mat-select>-->
                    <p>{{'UserMgmt.Role' | translate}}: <b>{{typeForm.charAt(0).toUpperCase() + typeForm.slice(1)}}</b>
                    </p>
                </div>
                <div class="form-group col-12 pl-0 pr-0">
                    <div class="formSection">
                        <p class="mb-0 mt-0 section-title">{{'UserForm.Sections.PersonalInfo' | translate}}</p>
                    </div>
                </div>
                <div class="col-12 mt-4 mb-4" *ngIf="data.action==='edit'">
                    <div class="user-icon" for="image-upload">
                        <mat-icon class="badge-delete-image" (click)="cancelImage()">close</mat-icon>
                        <label for="file-input">
                            <div class="user-img selectable" [style.backgroundImage]="'url('+ userImage +')'"></div>
                        </label>
                        <input for hidden (change)="uploadImage(fileInput)" #fileInput type="file" id="file-input"
                            accept="image/*">
                    </div>
                    <div *ngIf="errorFileSize" class="user-error-file-size">
                        <p>{{'UserForm.Errors.Pattern.Picture' | translate}}</p>
                    </div>
                </div>
                <div class="input-wrapper inputs-grid">
                    <div *ngIf="fieldsByRole['name'][typeForm]!==undefined">
                        <input id='name' formControlName="name" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.FirstName' | translate}}" type='text'
                            [ngClass]="{ 'is-invalid': submitted && userF.name.errors }" />
                        <div *ngIf="submitted && userF.name.errors" class="invalid-feedback">
                            <div *ngIf="userF.name.errors.required">{{'UserForm.Errors.Required.FirstName' | translate}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="fieldsByRole['surname'][typeForm]!==undefined">
                        <input id='surname' formControlName="surname" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.LastName' | translate}}" type='text'
                            [ngClass]="{ 'is-invalid': submitted && userF.surname.errors }" />
                        <div *ngIf="submitted && userF.surname.errors" class="invalid-feedback">
                            <div *ngIf="userF.surname.errors.required">{{'UserForm.Errors.Required.LastName' | translate}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="fieldsByRole['fiscalCode'][typeForm]!==undefined">
                        <input id='fiscalCode' formControlName="fiscalCode" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.FiscalCode' | translate}}" type='text'
                            [ngClass]="{ 'is-invalid': submitted && userF.fiscalCode.errors }" />
                        <div *ngIf="submitted && userF.fiscalCode.errors" class="invalid-feedback">
                            <div *ngIf="userF.fiscalCode.errors.required">{{'UserForm.Errors.Required.FiscalCode' |
                                translate}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="fieldsByRole['businessName'][typeForm]!==undefined">
                        <input id='businessName' formControlName="businessName" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.BusinessName' | translate}}" type='text'
                            [ngClass]="{ 'is-invalid': submitted && userF.businessName.errors }" />
                        <div *ngIf="submitted && userF.businessName.errors" class="invalid-feedback">
                            <div *ngIf="userF.businessName.errors.required">{{'UserForm.Errors.Required.BusinessName' |
                                translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="formSection">
                        <p class="section-title">{{'UserForm.Sections.AccountInfo' | translate}}</p>
                    </div>
                </div>
                <div class="input-wrapper inputs-grid">
                    <div>
                        <input id='email' formControlName="email" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.Email' | translate}}" type='text'
                            [disabled]="data.action==='edit'"
                            [ngClass]="{ 'is-invalid': submitted && userF.email.errors }" />
                        <div *ngIf="submitted && userF.email.errors" class="invalid-feedback">
                            <div *ngIf="userF.email.errors.required">{{'UserForm.Errors.Required.Email' | translate}}</div>
                            <div *ngIf="userF.email.errors.pattern">{{'UserForm.Errors.Pattern.Email' | translate}}</div>
                        </div>
                    </div>
                    <div *ngIf="fieldsByRole['language'][typeForm]!==undefined">
                        <select id='language' formControlName="language" matInput class="basic-input"
                            placeholder="" type='text'
                            [ngClass]="{ 'select-language': true, 'is-selected': !languageSelected, 'is-invalid': submitted && userF.language.errors }">
                            <option value="">{{'UserForm.Fields.Language' | translate}}</option>
                            <option *ngFor="let lang of languageList" [value]="lang.value">
                                {{'Languages.'+lang.name | translate}}
                            </option>
                        </select>
                        <div *ngIf="submitted && userF.language.errors" class="invalid-feedback">
                            <div *ngIf="userF.language.errors.required">{{'UserForm.Errors.Required.Language' | translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="typeForm!==Role.Factory">
                    <div class="formSection">
                        <p class="section-title">{{'UserForm.Sections.ContactInfo' | translate}}</p>
                    </div>
                </div>
                <div class="input-wrapper inputs-grid">
                    <div *ngIf="fieldsByRole['phoneOffice'][typeForm]!==undefined">
                        <input id='phoneOffice' formControlName="phoneOffice" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.OfficePhoneNumber' | translate}}" type='text'
                            (keypress)="numbersAndPlus($event)"
                            [ngClass]="{ 'is-invalid': submitted && userF.phoneOffice.errors }" />
                        <div *ngIf="submitted && userF.phoneOffice.errors" class="invalid-feedback">
                            <div *ngIf="userF.phoneOffice.errors.required">{{'UserForm.Errors.Required.OfficePhoneNumber' |
                                translate}}</div>
                            <div *ngIf="userF.phoneOffice.errors.pattern">{{'UserForm.Errors.Pattern.OfficePhoneNumber' |
                                translate}}</div>
                        </div>
                    </div>
                    <div *ngIf="fieldsByRole['phoneMobile'][typeForm]!==undefined">
                        <input id='phoneMobile' formControlName="phoneMobile" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.MobilePhoneNumber' | translate}}" type='text'
                            (keypress)="numbersAndPlus($event)"
                            [ngClass]="{ 'is-invalid': submitted && userF.phoneMobile.errors }" />
                        <div *ngIf="submitted && userF.phoneMobile.errors" class="invalid-feedback">
                            <div *ngIf="userF.phoneMobile.errors.pattern">{{'UserForm.Errors.Pattern.MobilePhoneNumber' |
                                translate}}</div>
                        </div>
                    </div>
                    <div *ngIf="fieldsByRole['city'][typeForm]!==undefined">
                        <input id='city' formControlName="city" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.City' | translate}}" type='text'
                            [ngClass]="{ 'is-invalid': submitted && userF.city.errors }" />
                        <div *ngIf="submitted && userF.city.errors" class="invalid-feedback">
                            <div *ngIf="userF.city.errors.required">{{'UserForm.Errors.Required.City' | translate}}</div>
                        </div>
                    </div>
                    <div *ngIf="fieldsByRole['country'][typeForm]!==undefined">
                        <select id='country' formControlName="country" matInput class="basic-input"
                            placeholder="" type='text'
                            (selectionChange)="setCountryFlag()"
                            [ngClass]="{ 'select-language': true, 'is-selected': !countrySelected, 'is-invalid': submitted && userF.country.errors }">
                            <option value="">{{'UserForm.Fields.Country' | translate}}</option>
                            <option *ngFor="let countryItem of countryList" [value]="countryItem.code">
                                {{countryItem.name}}
                            </option>
                        </select>
                        <div *ngIf="submitted && userF.country.errors" class="invalid-feedback">
                            <div *ngIf="userF.country.errors.required">{{'UserForm.Errors.Required.Country' | translate}}
                            </div>
                        </div>
                    </div>
                    <div *ngIf="fieldsByRole['addressStreet'][typeForm]!==undefined">
                        <input id='addressStreet' formControlName="addressStreet" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.Street' | translate}}" type='text'
                            [ngClass]="{ 'is-invalid': submitted && userF.addressStreet.errors }" />
                        <div *ngIf="submitted && userF.addressStreet.errors" class="invalid-feedback">
                            <div *ngIf="userF.addressStreet.errors.required">{{'UserForm.Errors.Required.Street' |
                                translate}}
                            </div>
                        </div>
                    </div>
                    <div class="form-group-double">
                        <div *ngIf="fieldsByRole['addressNumber'][typeForm]!==undefined">
                            <input id='addressNumber' formControlName="addressNumber" matInput class="basic-input"
                                placeholder="{{'UserForm.Fields.Number' | translate}}" type='text'
                                [ngClass]="{ 'is-invalid': submitted && userF.addressNumber.errors }"
                                maxlength="10" />
                            <div *ngIf="submitted && userF.addressNumber.errors" class="invalid-feedback">
                                <div *ngIf="userF.addressNumber.errors.required">{{'UserForm.Errors.Required.Number' |
                                    translate}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="fieldsByRole['addressPostalCode'][typeForm]!==undefined">
                            <input id='addressPostalCode' formControlName="addressPostalCode" matInput class="basic-input"
                                placeholder="{{'UserForm.Fields.PostalCode' | translate}}" type='text'
                                maxlength="10" (keypress)="numbersAndLetters($event)"
                                [ngClass]="{ 'is-invalid': submitted && userF.addressPostalCode.errors }" />
                            <div *ngIf="submitted && userF.addressPostalCode.errors" class="invalid-feedback">
                                <div *ngIf="userF.addressPostalCode.errors.required">{{'UserForm.Errors.Required.PostalCode' |
                                    translate}}</div>
                                <div *ngIf="userF.addressPostalCode.errors.pattern">{{'UserForm.Errors.Pattern.PostalCode' |
                                    translate}}</div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="fieldsByRole['contactEmail'][typeForm]!==undefined">
                        <input id='contactEmail' formControlName="contactEmail" matInput class="basic-input"
                            placeholder="{{'UserForm.Fields.ContactEmail' | translate}}" type='text'
                            [ngClass]="{ 'is-invalid': submitted && userF.contactEmail.errors }" />
                        <div *ngIf="submitted && userF.contactEmail.errors" class="invalid-feedback">
                            <div *ngIf="userF.contactEmail.errors.required">{{'UserForm.Errors.Required.ContactEmail' |
                                translate}}</div>
                            <div *ngIf="userF.contactEmail.errors.pattern">{{'UserForm.Errors.Pattern.ContactEmail' |
                                translate}}</div>
                        </div>
                    </div>
                </div>
                <div class="form-group col-12 pt-2 pl-0 pr-0" *ngIf="typeForm===Role.Customer">
                    <div class="formSection">
                        <p class="section-title">{{'UserForm.Sections.TermsOfUse' | translate}}</p>
                    </div>
                </div>
                <div class="form-group col-12 d-flex pl-0 pr-0 mb-2" *ngIf="typeForm===Role.Customer">
                    <mat-checkbox id="termsOfUse" formControlName="termsOfUse" disabled></mat-checkbox>
                    <p class="inline checkbox-description">{{'UserForm.Fields.TermsOfUse' | translate}} <span><a class="selectable"
                                (click)="goToPage('legals/1')">{{'UserForm.Fields.TermsOfUseLink' |
                                translate}}</a></span>*.</p>
                </div>
                <div class="form-group col-12 pl-0 pr-0" *ngIf="typeForm===Role.Customer">
                    <div class="formSection">
                        <p class="section-title">{{'UserForm.Sections.PrivacyTerms' | translate}}</p>
                    </div>
                </div>
                <div class="form-group col-12 pl-0 pr-0 mb-2 d-flex" *ngIf="typeForm===Role.Customer">
                    <mat-checkbox id="privacy" formControlName="privacy" class="inline" disabled>
                    </mat-checkbox>
                    <p class="inline checkbox-description">{{'UserForm.Fields.PrivacyBox1' | translate}} <span><a class="selectable"
                                (click)="goToPage('legals/0')">{{'UserForm.Fields.PrivacyBox1Link' |
                                translate}}</a></span>*.</p>
                </div>
                <div class="form-group col-12 pl-0 pr-0 mb-2 d-flex" *ngIf="typeForm===Role.Customer">
                    <mat-checkbox id="privacyOne" formControlName="privacyOne" class="inline">
                    </mat-checkbox>
                    <p class="inline checkbox-description">{{'UserForm.Fields.PrivacyBox2' | translate}}</p>
                </div>
                <div class="form-group col-12 pl-0 pr-0 mb-2 d-flex" *ngIf="typeForm===Role.Customer">
                    <mat-checkbox id="privacyTwo" formControlName="privacyTwo" class="inline">
                    </mat-checkbox>
                    <p class="inline checkbox-description">{{'UserForm.Fields.PrivacyBox3' | translate}}</p>
                </div>
                <div class="form-group col-12 pl-0 pr-0 mb-2 d-flex" *ngIf="typeForm===Role.Customer">
                    <mat-checkbox id="sharprivacyThreeeData" formControlName="privacyThree" class="inline">
                    </mat-checkbox>
                    <p class="inline checkbox-description">{{'UserForm.Fields.PrivacyBox4' | translate}}</p>
                </div>
            </div>

            <div class="stepper-buttons">
                <button class="btn btn-secondary sdf-button mr-3" (click)="onNoClick()"
                    type="button">{{'UserMgmt.Dialogs.Actions.Cancel' |
                    translate}}</button>
                <button class="btn btn-primary sdf-button" type="submit"
                    [disabled]="data.action==='edit' && !userForm.dirty && !imageUploaded">{{action}}</button>
            </div>

        </form>
    </div>
</div>
