<div class="dialog-content" style="background: #F2F2F2;">
  <div class='header-title'>
      <div class='title-toolbar'>
        <div class="title-text">{{'EquipManagement.Fields.AddImplement' | translate }}</div>
        <div>
          <button (click)='onNoClick()' class='close-button' mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
  </div>
  <div class="add-equip-container">
      <app-equip-form></app-equip-form>
  </div>
</div>
