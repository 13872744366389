<div class="message-container">
  <div class='header-title'>
    <div class='title-toolbar'>
      <div class="title-text">{{data.title}}</div>
      <div>
        <button (click)='closeDialog()' class='close-button' mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <!-- <div *ngIf="data.title !== ''"  class="dialog-message-title" mat-dialog-title>{{data.title}}</div> -->
  <div class="message-content" mat-dialog-content>{{data.text}}</div>

<!--  ACTIONS-->
<!--  MESSAGE DIALOG-->
  <div  *ngIf="!data.isConfirm" mat-dialog-actions class="d-flex justify-content-center">
    <button class="btn btn-primary sdf-button" (click)="closeDialog()">{{'MessageDialog.Actions.Confirm' | translate}}</button>
  </div>
<!--  CONFIRM DIALOG-->
  <div  *ngIf="data.isConfirm" mat-dialog-actions class="actions-wrapper">
    <button class="btn btn-secondary sdf-button"  (click)="closeDialog()">{{'MessageDialog.Actions.Cancel' | translate}}</button>
    <button class="btn btn-primary sdf-button" [mat-dialog-close]="true">{{'MessageDialog.Actions.Confirm' | translate}}</button>
  </div>
</div>


